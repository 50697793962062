import { traverse } from '../traverse';
export function sampleObject(schema) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var spec = arguments.length > 2 ? arguments[2] : undefined;
  var context = arguments.length > 3 ? arguments[3] : undefined;
  var res = {};
  var depth = context && context.depth || 1;

  if (schema && typeof schema.properties === 'object') {
    var requiredKeys = Array.isArray(schema.required) ? schema.required : [];
    var requiredKeyDict = requiredKeys.reduce(function (dict, key) {
      dict[key] = true;
      return dict;
    }, {});
    Object.keys(schema.properties).forEach(function (propertyName) {
      // skip before traverse that could be costly
      if (options.skipNonRequired && !requiredKeyDict.hasOwnProperty(propertyName)) {
        return;
      }

      var sample = traverse(schema.properties[propertyName], options, spec, {
        propertyName: propertyName,
        depth: depth + 1
      });

      if (options.skipReadOnly && sample.readOnly) {
        return;
      }

      if (options.skipWriteOnly && sample.writeOnly) {
        return;
      }

      res[propertyName] = sample.value;
    });
  }

  if (schema && typeof schema.additionalProperties === 'object') {
    res.property1 = traverse(schema.additionalProperties, options, spec, {
      depth: depth + 1
    }).value;
    res.property2 = traverse(schema.additionalProperties, options, spec, {
      depth: depth + 1
    }).value;
  }

  return res;
}