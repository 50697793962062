var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

import { useCallback, useState } from "react";
var EMPTY_ARRAY = [];
export function useForceUpdate() {
  var _a = __read(useState(0), 2),
      setTick = _a[1];

  var update = useCallback(function () {
    setTick(function (tick) {
      return tick + 1;
    });
  }, EMPTY_ARRAY);
  return update;
}
var deprecatedMessages = [];
export function useDeprecated(msg) {
  if (!deprecatedMessages.includes(msg)) {
    deprecatedMessages.push(msg);
    console.warn(msg);
  }
}