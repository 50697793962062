"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSchemaRefs = exports.resolveUrl = exports.normalizeId = exports._getFullPath = exports.getFullPath = exports.inlineRef = void 0;

var util_1 = require("./util");

var equal = require("fast-deep-equal");

var traverse = require("json-schema-traverse");

var URI = require("uri-js"); // TODO refactor to use keyword definitions


var SIMPLE_INLINED = new Set(["type", "format", "pattern", "maxLength", "minLength", "maxProperties", "minProperties", "maxItems", "minItems", "maximum", "minimum", "uniqueItems", "multipleOf", "required", "enum", "const"]);

function inlineRef(schema) {
  var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (typeof schema == "boolean") return true;
  if (limit === true) return !hasRef(schema);
  if (!limit) return false;
  return countKeys(schema) <= limit;
}

exports.inlineRef = inlineRef;
var REF_KEYWORDS = new Set(["$ref", "$recursiveRef", "$recursiveAnchor", "$dynamicRef", "$dynamicAnchor"]);

function hasRef(schema) {
  for (var key in schema) {
    if (REF_KEYWORDS.has(key)) return true;
    var sch = schema[key];
    if (Array.isArray(sch) && sch.some(hasRef)) return true;
    if (typeof sch == "object" && hasRef(sch)) return true;
  }

  return false;
}

function countKeys(schema) {
  var count = 0;

  for (var key in schema) {
    if (key === "$ref") return Infinity;
    count++;
    if (SIMPLE_INLINED.has(key)) continue;

    if (typeof schema[key] == "object") {
      util_1.eachItem(schema[key], function (sch) {
        return count += countKeys(sch);
      });
    }

    if (count === Infinity) return Infinity;
  }

  return count;
}

function getFullPath() {
  var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var normalize = arguments.length > 1 ? arguments[1] : undefined;
  if (normalize !== false) id = normalizeId(id);
  var p = URI.parse(id);
  return _getFullPath(p);
}

exports.getFullPath = getFullPath;

function _getFullPath(p) {
  return URI.serialize(p).split("#")[0] + "#";
}

exports._getFullPath = _getFullPath;
var TRAILING_SLASH_HASH = /#\/?$/;

function normalizeId(id) {
  return id ? id.replace(TRAILING_SLASH_HASH, "") : "";
}

exports.normalizeId = normalizeId;

function resolveUrl(baseId, id) {
  id = normalizeId(id);
  return URI.resolve(baseId, id);
}

exports.resolveUrl = resolveUrl;
var ANCHOR = /^[a-z_][-a-z0-9._]*$/i;

function getSchemaRefs(schema) {
  var _this = this;

  if (typeof schema == "boolean") return {};
  var schemaId = this.opts.schemaId;
  var schId = normalizeId(schema[schemaId]);
  var baseIds = {
    "": schId
  };
  var pathPrefix = getFullPath(schId, false);
  var localRefs = {};
  var schemaRefs = new Set();
  traverse(schema, {
    allKeys: true
  }, function (sch, jsonPtr, _, parentJsonPtr) {
    if (parentJsonPtr === undefined) return;
    var fullPath = pathPrefix + jsonPtr;
    var baseId = baseIds[parentJsonPtr];
    if (typeof sch[schemaId] == "string") baseId = addRef.call(_this, sch[schemaId]);
    addAnchor.call(_this, sch.$anchor);
    addAnchor.call(_this, sch.$dynamicAnchor);
    baseIds[jsonPtr] = baseId;

    function addRef(ref) {
      ref = normalizeId(baseId ? URI.resolve(baseId, ref) : ref);
      if (schemaRefs.has(ref)) throw ambiguos(ref);
      schemaRefs.add(ref);
      var schOrRef = this.refs[ref];
      if (typeof schOrRef == "string") schOrRef = this.refs[schOrRef];

      if (typeof schOrRef == "object") {
        checkAmbiguosRef(sch, schOrRef.schema, ref);
      } else if (ref !== normalizeId(fullPath)) {
        if (ref[0] === "#") {
          checkAmbiguosRef(sch, localRefs[ref], ref);
          localRefs[ref] = sch;
        } else {
          this.refs[ref] = fullPath;
        }
      }

      return ref;
    }

    function addAnchor(anchor) {
      if (typeof anchor == "string") {
        if (!ANCHOR.test(anchor)) throw new Error("invalid anchor \"".concat(anchor, "\""));
        addRef.call(this, "#".concat(anchor));
      }
    }
  });
  return localRefs;

  function checkAmbiguosRef(sch1, sch2, ref) {
    if (sch2 !== undefined && !equal(sch1, sch2)) throw ambiguos(ref);
  }

  function ambiguos(ref) {
    return new Error("reference \"".concat(ref, "\" resolves to more than one schema"));
  }
}

exports.getSchemaRefs = getSchemaRefs;