"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Oas3Types = void 0;

var _1 = require(".");

var ref_utils_1 = require("../ref-utils");

var responseCodeRegexp = /^[0-9][0-9Xx]{2}$/;
var DefinitionRoot = {
  properties: {
    openapi: null,
    info: 'Info',
    servers: _1.listOf('Server'),
    security: _1.listOf('SecurityRequirement'),
    tags: _1.listOf('Tag'),
    externalDocs: 'ExternalDocs',
    paths: 'PathMap',
    components: 'Components',
    'x-webhooks': 'WebhooksMap'
  },
  required: ['openapi', 'paths', 'info']
};
var Tag = {
  properties: {
    name: {
      type: 'string'
    },
    description: {
      type: 'string'
    },
    externalDocs: 'ExternalDocs'
  },
  required: ['name']
};
var ExternalDocs = {
  properties: {
    description: {
      type: 'string'
    },
    url: {
      type: 'string'
    }
  },
  required: ['url']
};
var Server = {
  properties: {
    url: {
      type: 'string'
    },
    description: {
      type: 'string'
    },
    variables: _1.mapOf('ServerVariable')
  },
  required: ['url']
};
var ServerVariable = {
  properties: {
    enum: {
      type: 'array',
      items: {
        type: 'string'
      }
    },
    default: {
      type: 'string'
    },
    description: null
  },
  required: ['default']
};
var SecurityRequirement = {
  properties: {},
  additionalProperties: {
    type: 'array',
    items: {
      type: 'string'
    }
  }
};
var Info = {
  properties: {
    title: {
      type: 'string'
    },
    version: {
      type: 'string'
    },
    description: {
      type: 'string'
    },
    termsOfService: {
      type: 'string'
    },
    contact: 'Contact',
    license: 'License'
  },
  required: ['title', 'version']
};
var Contact = {
  properties: {
    name: {
      type: 'string'
    },
    url: {
      type: 'string'
    },
    email: {
      type: 'string'
    }
  }
};
var License = {
  properties: {
    name: {
      type: 'string'
    },
    url: {
      type: 'string'
    }
  },
  required: ['name']
};
var PathMap = {
  properties: {},
  additionalProperties: function additionalProperties(_value, key) {
    return key.startsWith('/') ? 'PathItem' : undefined;
  }
};
var WebhooksMap = {
  properties: {},
  additionalProperties: function additionalProperties() {
    return 'PathItem';
  }
};
var PathItem = {
  properties: {
    $ref: {
      type: 'string'
    },
    servers: _1.listOf('Server'),
    parameters: _1.listOf('Parameter'),
    summary: {
      type: 'string'
    },
    description: {
      type: 'string'
    },
    get: 'Operation',
    put: 'Operation',
    post: 'Operation',
    delete: 'Operation',
    options: 'Operation',
    head: 'Operation',
    patch: 'Operation',
    trace: 'Operation'
  }
};
var Parameter = {
  properties: {
    name: {
      type: 'string'
    },
    in: {
      enum: ['query', 'header', 'path', 'cookie']
    },
    description: {
      type: 'string'
    },
    required: {
      type: 'boolean'
    },
    deprecated: {
      type: 'boolean'
    },
    allowEmptyValue: {
      type: 'boolean'
    },
    style: {
      enum: ['form', 'simple', 'label', 'matrix', 'spaceDelimited', 'pipeDelimited', 'deepObject']
    },
    explode: {
      type: 'boolean'
    },
    allowReserved: {
      type: 'boolean'
    },
    schema: 'Schema',
    example: {
      isExample: true
    },
    examples: _1.mapOf('Example'),
    content: 'MediaTypeMap'
  },
  required: ['name', 'in']
};
var Callback = {
  properties: {},
  additionalProperties: 'PathItem'
};
var Operation = {
  properties: {
    tags: {
      type: 'array',
      items: {
        type: 'string'
      }
    },
    summary: {
      type: 'string'
    },
    description: {
      type: 'string'
    },
    externalDocs: 'ExternalDocs',
    operationId: {
      type: 'string'
    },
    parameters: _1.listOf('Parameter'),
    security: _1.listOf('SecurityRequirement'),
    servers: _1.listOf('Server'),
    requestBody: 'RequestBody',
    responses: 'ResponsesMap',
    deprecated: {
      type: 'boolean'
    },
    callbacks: _1.mapOf('Callback'),
    'x-codeSamples': _1.listOf('XCodeSample'),
    'x-code-samples': _1.listOf('XCodeSample') // deprecated

  },
  required: ['responses']
};
var XCodeSample = {
  properties: {
    lang: {
      type: 'string'
    },
    label: {
      type: 'string'
    },
    source: {
      type: 'string'
    }
  }
};
var RequestBody = {
  properties: {
    description: {
      type: 'string'
    },
    required: {
      type: 'boolean'
    },
    content: 'MediaTypeMap'
  },
  required: ['content']
};
var MediaTypeMap = {
  properties: {},
  additionalProperties: 'MediaType'
};
var MediaType = {
  properties: {
    schema: 'Schema',
    example: {
      isExample: true
    },
    examples: _1.mapOf('Example'),
    encoding: _1.mapOf('Encoding')
  }
};
var Example = {
  properties: {
    value: {
      isExample: true
    },
    summary: {
      type: 'string'
    },
    description: {
      type: 'string'
    },
    externalValue: {
      type: 'string'
    }
  }
};
var Encoding = {
  properties: {
    contentType: {
      type: 'string'
    },
    headers: _1.mapOf('Header'),
    style: {
      enum: ['form', 'simple', 'label', 'matrix', 'spaceDelimited', 'pipeDelimited', 'deepObject']
    },
    explode: {
      type: 'boolean'
    },
    allowReserved: {
      type: 'boolean'
    }
  }
};
var Header = {
  properties: {
    description: {
      type: 'string'
    },
    required: {
      type: 'boolean'
    },
    deprecated: {
      type: 'boolean'
    },
    allowEmptyValue: {
      type: 'boolean'
    },
    style: {
      enum: ['form', 'simple', 'label', 'matrix', 'spaceDelimited', 'pipeDelimited', 'deepObject']
    },
    explode: {
      type: 'boolean'
    },
    allowReserved: {
      type: 'boolean'
    },
    schema: 'Schema',
    example: {
      isExample: true
    },
    examples: _1.mapOf('Example'),
    content: 'MediaTypeMap'
  }
};
var ResponsesMap = {
  properties: {
    default: 'Response'
  },
  additionalProperties: function additionalProperties(_v, key) {
    return responseCodeRegexp.test(key) ? 'Response' : undefined;
  }
};
var Response = {
  properties: {
    description: {
      type: 'string'
    },
    headers: _1.mapOf('Header'),
    content: 'MediaTypeMap',
    links: _1.mapOf('Link')
  },
  required: ['description']
};
var Link = {
  properties: {
    operationRef: {
      type: 'string'
    },
    operationId: {
      type: 'string'
    },
    parameters: null,
    requestBody: null,
    description: {
      type: 'string'
    },
    server: 'Server'
  }
};
var Schema = {
  properties: {
    externalDocs: 'ExternalDocs',
    discriminator: 'Discriminator',
    title: {
      type: 'string'
    },
    multipleOf: {
      type: 'number',
      minimum: 0
    },
    maximum: {
      type: 'number'
    },
    minimum: {
      type: 'number'
    },
    exclusiveMaximum: {
      type: 'boolean'
    },
    exclusiveMinimum: {
      type: 'boolean'
    },
    maxLength: {
      type: 'number',
      minimum: 0
    },
    minLength: {
      type: 'number',
      minimum: 0
    },
    pattern: {
      type: 'string'
    },
    maxItems: {
      type: 'number',
      minimum: 0
    },
    minItems: {
      type: 'number',
      minimum: 0
    },
    uniqueItems: {
      type: 'boolean'
    },
    maxProperties: {
      type: 'number',
      minimum: 0
    },
    minProperties: {
      type: 'number',
      minimum: 0
    },
    required: {
      type: 'array',
      items: {
        type: 'string'
      }
    },
    enum: {
      type: 'array'
    },
    type: {
      enum: ['object', 'array', 'string', 'number', 'integer', 'boolean', 'null']
    },
    allOf: _1.listOf('Schema'),
    anyOf: _1.listOf('Schema'),
    oneOf: _1.listOf('Schema'),
    not: 'Schema',
    properties: 'SchemaProperties',
    items: function items(value) {
      if (Array.isArray(value)) {
        return _1.listOf('Schema');
      } else {
        return 'Schema';
      }
    },
    additionalProperties: function additionalProperties(value) {
      if (typeof value === 'boolean') {
        return {
          type: 'boolean'
        };
      } else {
        return 'Schema';
      }
    },
    description: {
      type: 'string'
    },
    format: {
      type: 'string'
    },
    default: null,
    nullable: {
      type: 'boolean'
    },
    readOnly: {
      type: 'boolean'
    },
    writeOnly: {
      type: 'boolean'
    },
    xml: 'Xml',
    example: {
      isExample: true
    },
    deprecated: {
      type: 'boolean'
    }
  }
};
var Xml = {
  properties: {
    name: {
      type: 'string'
    },
    namespace: {
      type: 'string'
    },
    prefix: {
      type: 'string'
    },
    attribute: {
      type: 'boolean'
    },
    wrapped: {
      type: 'boolean'
    }
  }
};
var SchemaProperties = {
  properties: {},
  additionalProperties: 'Schema'
};
var DiscriminatorMapping = {
  properties: {},
  additionalProperties: function additionalProperties(value) {
    if (ref_utils_1.isMappingRef(value)) {
      return {
        type: 'string',
        directResolveAs: 'Schema'
      };
    } else {
      return {
        type: 'string'
      };
    }
  }
};
var Discriminator = {
  properties: {
    propertyName: {
      type: 'string'
    },
    mapping: 'DiscriminatorMapping'
  },
  required: ['propertyName']
};
var Components = {
  properties: {
    parameters: 'NamedParameters',
    schemas: 'NamedSchemas',
    responses: 'NamedResponses',
    examples: 'NamedExamples',
    requestBodies: 'NamedRequestBodies',
    headers: 'NamedHeaders',
    securitySchemes: 'NamedSecuritySchemes',
    links: 'NamedLinks',
    callbacks: 'NamedCallbacks'
  }
};
var ImplicitFlow = {
  properties: {
    refreshUrl: {
      type: 'string'
    },
    scopes: {
      type: 'object',
      additionalProperties: {
        type: 'string'
      }
    },
    authorizationUrl: {
      type: 'string'
    }
  },
  required: ['authorizationUrl', 'scopes']
};
var PasswordFlow = {
  properties: {
    refreshUrl: {
      type: 'string'
    },
    scopes: {
      type: 'object',
      additionalProperties: {
        type: 'string'
      }
    },
    tokenUrl: {
      type: 'string'
    }
  },
  required: ['tokenUrl', 'scopes']
};
var ClientCredentials = {
  properties: {
    refreshUrl: {
      type: 'string'
    },
    scopes: {
      type: 'object',
      additionalProperties: {
        type: 'string'
      }
    },
    tokenUrl: {
      type: 'string'
    }
  },
  required: ['tokenUrl', 'scopes']
};
var AuthorizationCode = {
  properties: {
    refreshUrl: {
      type: 'string'
    },
    authorizationUrl: {
      type: 'string'
    },
    scopes: {
      type: 'object',
      additionalProperties: {
        type: 'string'
      }
    },
    tokenUrl: {
      type: 'string'
    }
  },
  required: ['authorizationUrl', 'tokenUrl', 'scopes']
};
var SecuritySchemeFlows = {
  properties: {
    implicit: 'ImplicitFlow',
    password: 'PasswordFlow',
    clientCredentials: 'ClientCredentials',
    authorizationCode: 'AuthorizationCode'
  }
};
var SecurityScheme = {
  properties: {
    type: {
      enum: ['apiKey', 'http', 'oauth2', 'openIdConnect']
    },
    description: {
      type: 'string'
    },
    name: {
      type: 'string'
    },
    in: {
      type: 'string'
    },
    scheme: {
      type: 'string'
    },
    bearerFormat: {
      type: 'string'
    },
    flows: 'SecuritySchemeFlows',
    openIdConnectUrl: {
      type: 'string'
    }
  },
  required: function required(value) {
    if (!(value === null || value === void 0 ? void 0 : value.type)) {
      return ['type'];
    }

    if (value.type === 'apiKey') {
      return ['type', 'name', 'in'];
    } else if (value.type === 'http') {
      return ['type', 'scheme'];
    } else if (value.type === 'oauth2') {
      return ['type', 'flows'];
    } else if (value.type === 'openIdConnect') {
      return ['type', 'openIdConnectUrl'];
    }

    return ['type'];
  }
};
exports.Oas3Types = {
  DefinitionRoot: DefinitionRoot,
  Tag: Tag,
  ExternalDocs: ExternalDocs,
  Server: Server,
  ServerVariable: ServerVariable,
  SecurityRequirement: SecurityRequirement,
  Info: Info,
  Contact: Contact,
  License: License,
  PathMap: PathMap,
  PathItem: PathItem,
  Parameter: Parameter,
  Operation: Operation,
  Callback: Callback,
  RequestBody: RequestBody,
  MediaTypeMap: MediaTypeMap,
  MediaType: MediaType,
  Example: Example,
  Encoding: Encoding,
  Header: Header,
  ResponsesMap: ResponsesMap,
  Response: Response,
  Link: Link,
  Schema: Schema,
  Xml: Xml,
  SchemaProperties: SchemaProperties,
  DiscriminatorMapping: DiscriminatorMapping,
  Discriminator: Discriminator,
  Components: Components,
  NamedSchemas: _1.mapOf('Schema'),
  NamedResponses: _1.mapOf('Response'),
  NamedParameters: _1.mapOf('Parameter'),
  NamedExamples: _1.mapOf('Example'),
  NamedRequestBodies: _1.mapOf('RequestBody'),
  NamedHeaders: _1.mapOf('Header'),
  NamedSecuritySchemes: _1.mapOf('SecurityScheme'),
  NamedLinks: _1.mapOf('Link'),
  NamedCallbacks: _1.mapOf('PathItem'),
  ImplicitFlow: ImplicitFlow,
  PasswordFlow: PasswordFlow,
  ClientCredentials: ClientCredentials,
  AuthorizationCode: AuthorizationCode,
  SecuritySchemeFlows: SecuritySchemeFlows,
  SecurityScheme: SecurityScheme,
  XCodeSample: XCodeSample,
  WebhooksMap: WebhooksMap
};