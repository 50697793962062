"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var id_1 = require("./id");

var ref_1 = require("./ref");

var core = ["$schema", "$id", "$defs", "$vocabulary", {
  keyword: "$comment"
}, "definitions", id_1.default, ref_1.default];
exports.default = core;