"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var additionalItems_1 = require("./additionalItems");

var prefixItems_1 = require("./prefixItems");

var items_1 = require("./items");

var items2020_1 = require("./items2020");

var contains_1 = require("./contains");

var dependencies_1 = require("./dependencies");

var propertyNames_1 = require("./propertyNames");

var additionalProperties_1 = require("./additionalProperties");

var properties_1 = require("./properties");

var patternProperties_1 = require("./patternProperties");

var not_1 = require("./not");

var anyOf_1 = require("./anyOf");

var oneOf_1 = require("./oneOf");

var allOf_1 = require("./allOf");

var if_1 = require("./if");

var thenElse_1 = require("./thenElse");

function getApplicator() {
  var draft2020 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var applicator = [// any
  not_1.default, anyOf_1.default, oneOf_1.default, allOf_1.default, if_1.default, thenElse_1.default, // object
  propertyNames_1.default, additionalProperties_1.default, dependencies_1.default, properties_1.default, patternProperties_1.default]; // array

  if (draft2020) applicator.push(prefixItems_1.default, items2020_1.default);else applicator.push(additionalItems_1.default, items_1.default);
  applicator.push(contains_1.default);
  return applicator;
}

exports.default = getApplicator;