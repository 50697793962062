"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BooleanParameterPrefixes = void 0;

var BooleanParameterPrefixes = function BooleanParameterPrefixes(options) {
  var prefixes = options.prefixes || ['is', 'has'];
  var regexp = new RegExp("^(".concat(prefixes.join('|'), ")[A-Z-_]"));
  var wrappedPrefixes = prefixes.map(function (p) {
    return "`".concat(p, "`");
  });
  var prefixesString = wrappedPrefixes.length === 1 ? wrappedPrefixes[0] : wrappedPrefixes.slice(0, -1).join(', ') + ' or ' + wrappedPrefixes[prefixes.length - 1];
  return {
    Parameter: function Parameter(param, _ref) {
      var report = _ref.report,
          location = _ref.location;

      if (param.type === 'boolean' && !regexp.test(param.name)) {
        report({
          message: "Boolean parameter `".concat(param.name, "` should have ").concat(prefixesString, " prefix."),
          location: location.child('name')
        });
      }
    }
  };
};

exports.BooleanParameterPrefixes = BooleanParameterPrefixes;