"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DiscrError = void 0;
var DiscrError;

(function (DiscrError) {
  DiscrError["Tag"] = "tag";
  DiscrError["Mapping"] = "mapping";
})(DiscrError = exports.DiscrError || (exports.DiscrError = {}));