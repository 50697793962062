"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (object) {
  return Object.getOwnPropertySymbols(object).filter(function (keySymbol) {
    return Object.prototype.propertyIsEnumerable.call(object, keySymbol);
  });
};