"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PathHttpVerbsOrder = void 0;
var defaultOrder = ['get', 'head', 'post', 'put', 'patch', 'delete', 'options', 'trace'];

var PathHttpVerbsOrder = function PathHttpVerbsOrder(opts) {
  var order = opts && opts.order || defaultOrder;

  if (!Array.isArray(order)) {
    throw new Error('path-http-verbs-order `order` option must be an array');
  }

  return {
    PathItem: function PathItem(path, _ref) {
      var report = _ref.report,
          location = _ref.location;
      var httpVerbs = Object.keys(path).filter(function (k) {
        return order.includes(k);
      });

      for (var i = 0; i < httpVerbs.length - 1; i++) {
        var aIdx = order.indexOf(httpVerbs[i]);
        var bIdx = order.indexOf(httpVerbs[i + 1]);

        if (bIdx < aIdx) {
          report({
            message: 'Operation http verbs must be ordered.',
            location: Object.assign({
              reportOnKey: true
            }, location.child(httpVerbs[i + 1]))
          });
        }
      }
    }
  };
};

exports.PathHttpVerbsOrder = PathHttpVerbsOrder;