"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reportUnresolvedRef = exports.NoUnresolvedRefs = void 0;

var resolve_1 = require("../resolve");

var NoUnresolvedRefs = function NoUnresolvedRefs() {
  return {
    ref: {
      leave: function leave(_, _ref, resolved) {
        var report = _ref.report,
            location = _ref.location;
        if (resolved.node !== undefined) return;
        reportUnresolvedRef(resolved, report, location);
      }
    },
    DiscriminatorMapping: function DiscriminatorMapping(mapping, _ref2) {
      var report = _ref2.report,
          resolve = _ref2.resolve,
          location = _ref2.location;

      for (var _i = 0, _Object$keys = Object.keys(mapping); _i < _Object$keys.length; _i++) {
        var mappingName = _Object$keys[_i];
        var resolved = resolve({
          $ref: mapping[mappingName]
        });
        if (resolved.node !== undefined) return;
        reportUnresolvedRef(resolved, report, location.child(mappingName));
      }
    }
  };
};

exports.NoUnresolvedRefs = NoUnresolvedRefs;

function reportUnresolvedRef(resolved, report, location) {
  var _a;

  var error = resolved.error;

  if (error instanceof resolve_1.YamlParseError) {
    report({
      message: 'Failed to parse: ' + error.message,
      location: {
        source: error.source,
        pointer: undefined,
        start: {
          col: error.col,
          line: error.line
        }
      }
    });
  }

  var message = (_a = resolved.error) === null || _a === void 0 ? void 0 : _a.message;
  report({
    location: location,
    message: "Can't resolve $ref".concat(message ? ': ' + message : '')
  });
}

exports.reportUnresolvedRef = reportUnresolvedRef;