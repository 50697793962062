var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
      m = s && o[s],
      i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function next() {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};

import { CLEANUP_TIMER_LOOP_MILLIS, createTrackingData } from "./reactionCleanupTrackingCommon";
/**
 * timers, gc-style, uncommitted reaction cleanup
 */

export function createTimerBasedReactionCleanupTracking() {
  /**
   * Reactions created by components that have yet to be fully mounted.
   */
  var uncommittedReactionRefs = new Set();
  /**
   * Latest 'uncommitted reactions' cleanup timer handle.
   */

  var reactionCleanupHandle;
  /* istanbul ignore next */

  /**
   * Only to be used by test functions; do not export outside of mobx-react-lite
   */

  function forceCleanupTimerToRunNowForTests() {
    // This allows us to control the execution of the cleanup timer
    // to force it to run at awkward times in unit tests.
    if (reactionCleanupHandle) {
      clearTimeout(reactionCleanupHandle);
      cleanUncommittedReactions();
    }
  }
  /* istanbul ignore next */


  function resetCleanupScheduleForTests() {
    var e_1, _a;

    if (uncommittedReactionRefs.size > 0) {
      try {
        for (var uncommittedReactionRefs_1 = __values(uncommittedReactionRefs), uncommittedReactionRefs_1_1 = uncommittedReactionRefs_1.next(); !uncommittedReactionRefs_1_1.done; uncommittedReactionRefs_1_1 = uncommittedReactionRefs_1.next()) {
          var ref = uncommittedReactionRefs_1_1.value;
          var tracking = ref.current;

          if (tracking) {
            tracking.reaction.dispose();
            ref.current = null;
          }
        }
      } catch (e_1_1) {
        e_1 = {
          error: e_1_1
        };
      } finally {
        try {
          if (uncommittedReactionRefs_1_1 && !uncommittedReactionRefs_1_1.done && (_a = uncommittedReactionRefs_1.return)) _a.call(uncommittedReactionRefs_1);
        } finally {
          if (e_1) throw e_1.error;
        }
      }

      uncommittedReactionRefs.clear();
    }

    if (reactionCleanupHandle) {
      clearTimeout(reactionCleanupHandle);
      reactionCleanupHandle = undefined;
    }
  }

  function ensureCleanupTimerRunning() {
    if (reactionCleanupHandle === undefined) {
      reactionCleanupHandle = setTimeout(cleanUncommittedReactions, CLEANUP_TIMER_LOOP_MILLIS);
    }
  }

  function scheduleCleanupOfReactionIfLeaked(ref) {
    uncommittedReactionRefs.add(ref);
    ensureCleanupTimerRunning();
  }

  function recordReactionAsCommitted(reactionRef) {
    uncommittedReactionRefs.delete(reactionRef);
  }
  /**
   * Run by the cleanup timer to dispose any outstanding reactions
   */


  function cleanUncommittedReactions() {
    reactionCleanupHandle = undefined; // Loop through all the candidate leaked reactions; those older
    // than CLEANUP_LEAKED_REACTIONS_AFTER_MILLIS get tidied.

    var now = Date.now();
    uncommittedReactionRefs.forEach(function (ref) {
      var tracking = ref.current;

      if (tracking) {
        if (now >= tracking.cleanAt) {
          // It's time to tidy up this leaked reaction.
          tracking.reaction.dispose();
          ref.current = null;
          uncommittedReactionRefs.delete(ref);
        }
      }
    });

    if (uncommittedReactionRefs.size > 0) {
      // We've just finished a round of cleanups but there are still
      // some leak candidates outstanding.
      ensureCleanupTimerRunning();
    }
  }

  return {
    addReactionToTrack: function addReactionToTrack(reactionTrackingRef, reaction,
    /**
     * On timer based implementation we don't really need this object,
     * but we keep the same api
     */
    objectRetainedByReact) {
      reactionTrackingRef.current = createTrackingData(reaction);
      scheduleCleanupOfReactionIfLeaked(reactionTrackingRef);
      return reactionTrackingRef.current;
    },
    recordReactionAsCommitted: recordReactionAsCommitted,
    forceCleanupTimerToRunNowForTests: forceCleanupTimerToRunNowForTests,
    resetCleanupScheduleForTests: resetCleanupScheduleForTests
  };
}