"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var codegen_1 = require("./codegen");

var names = {
  // validation function arguments
  data: new codegen_1.Name("data"),
  // args passed from referencing schema
  valCxt: new codegen_1.Name("valCxt"),
  instancePath: new codegen_1.Name("instancePath"),
  parentData: new codegen_1.Name("parentData"),
  parentDataProperty: new codegen_1.Name("parentDataProperty"),
  rootData: new codegen_1.Name("rootData"),
  dynamicAnchors: new codegen_1.Name("dynamicAnchors"),
  // function scoped variables
  vErrors: new codegen_1.Name("vErrors"),
  errors: new codegen_1.Name("errors"),
  this: new codegen_1.Name("this"),
  // "globals"
  self: new codegen_1.Name("self"),
  scope: new codegen_1.Name("scope"),
  // JTD serialize/parse name for JSON string and position
  json: new codegen_1.Name("json"),
  jsonPos: new codegen_1.Name("jsonPos"),
  jsonLen: new codegen_1.Name("jsonLen"),
  jsonPart: new codegen_1.Name("jsonPart")
};
exports.default = names;