"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BooleanParameterPrefixes = void 0;

var BooleanParameterPrefixes = function BooleanParameterPrefixes(options) {
  var prefixes = options.prefixes || ['is', 'has'];
  var regexp = new RegExp("^(".concat(prefixes.join('|'), ")[A-Z-_]"));
  var wrappedPrefixes = prefixes.map(function (p) {
    return "`".concat(p, "`");
  });
  var prefixesString = wrappedPrefixes.length === 1 ? wrappedPrefixes[0] : wrappedPrefixes.slice(0, -1).join(', ') + ' or ' + wrappedPrefixes[prefixes.length - 1];
  return {
    Parameter: {
      Schema: function Schema(schema, _ref, parents) {
        var report = _ref.report,
            parentLocations = _ref.parentLocations;

        if (schema.type === 'boolean' && !regexp.test(parents.Parameter.name)) {
          report({
            message: "Boolean parameter `".concat(parents.Parameter.name, "` should have ").concat(prefixesString, " prefix."),
            location: parentLocations.Parameter.child(['name'])
          });
        }
      }
    }
  };
};

exports.BooleanParameterPrefixes = BooleanParameterPrefixes;