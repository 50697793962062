"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperationIdUrlSafe = void 0;
var validUrlSymbols = /^[A-Za-z0-9-._~:/?#\[\]@!\$&'()*+,;=]*$/;

var OperationIdUrlSafe = function OperationIdUrlSafe() {
  return {
    Operation: function Operation(operation, _ref) {
      var report = _ref.report,
          location = _ref.location;

      if (operation.operationId && !validUrlSymbols.test(operation.operationId)) {
        report({
          message: 'Operation `operationId` should not have URL invalid characters.',
          location: location.child(['operationId'])
        });
      }
    }
  };
};

exports.OperationIdUrlSafe = OperationIdUrlSafe;