"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperationIdUnique = void 0;

var OperationIdUnique = function OperationIdUnique() {
  var seenOperations = new Set();
  return {
    Operation: function Operation(operation, _ref) {
      var report = _ref.report,
          location = _ref.location;
      if (!operation.operationId) return;

      if (seenOperations.has(operation.operationId)) {
        report({
          message: 'Every operation must have a unique `operationId`.',
          location: location.child([operation.operationId])
        });
      }

      seenOperations.add(operation.operationId);
    }
  };
};

exports.OperationIdUnique = OperationIdUnique;