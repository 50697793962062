(function (global, factory) {
  if (typeof define === 'function' && define.amd) {
    define(['exports'], factory);
  } else if (typeof exports !== 'undefined') {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.decko = mod.exports;
  }
})(this, function (exports) {
  'use strict';

  exports.__esModule = true;
  var EMPTY = {};
  var HOP = Object.prototype.hasOwnProperty;
  var fns = {
    memoize: function memoize(fn) {
      var opt = arguments.length <= 1 || arguments[1] === undefined ? EMPTY : arguments[1];
      var cache = opt.cache || {};
      return function () {
        for (var _len = arguments.length, a = Array(_len), _key = 0; _key < _len; _key++) {
          a[_key] = arguments[_key];
        }

        var k = String(a[0]);
        if (opt.caseSensitive === false) k = k.toLowerCase();
        return HOP.call(cache, k) ? cache[k] : cache[k] = fn.apply(this, a);
      };
    },
    debounce: function debounce(fn, opts) {
      if (typeof opts === 'function') {
        var p = fn;
        fn = opts;
        opts = p;
      }

      var delay = opts && opts.delay || opts || 0,
          args = undefined,
          context = undefined,
          timer = undefined;
      return function () {
        for (var _len2 = arguments.length, a = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          a[_key2] = arguments[_key2];
        }

        args = a;
        context = this;
        if (!timer) timer = setTimeout(function () {
          fn.apply(context, args);
          args = context = timer = null;
        }, delay);
      };
    },
    bind: function bind(target, key, _ref) {
      var fn = _ref.value;
      return {
        configurable: true,
        get: function get() {
          var value = fn.bind(this);
          Object.defineProperty(this, key, {
            value: value,
            configurable: true,
            writable: true
          });
          return value;
        }
      };
    }
  };
  var memoize = multiMethod(fns.memoize),
      debounce = multiMethod(fns.debounce),
      bind = multiMethod(function (f, c) {
    return f.bind(c);
  }, function () {
    return fns.bind;
  });
  exports.memoize = memoize;
  exports.debounce = debounce;
  exports.bind = bind;
  exports['default'] = {
    memoize: memoize,
    debounce: debounce,
    bind: bind
  };

  function multiMethod(inner, deco) {
    deco = deco || inner.decorate || decorator(inner);
    var d = deco();
    return function () {
      for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        args[_key3] = arguments[_key3];
      }

      var l = args.length;
      return (l < 2 ? deco : l > 2 ? d : inner).apply(undefined, args);
    };
  }

  function decorator(fn) {
    return function (opt) {
      return typeof opt === 'function' ? fn(opt) : function (target, key, desc) {
        desc.value = fn(desc.value, opt, target, key, desc);
      };
    };
  }
});