"use strict";

exports.__esModule = true;
exports.default = void 0;
/**
 * Get a manifest filename depending on localized pathname
 *
 * @param {string} pathname
 * @param {Array<{start_url: string, lang: string}>} localizedManifests
 * @return string
 */

var _default = function _default(pathname, localizedManifests) {
  var defaultFilename = "manifest.webmanifest";

  if (!Array.isArray(localizedManifests)) {
    return defaultFilename;
  }

  var localizedManifest = localizedManifests.find(function (app) {
    return pathname.startsWith(app.start_url);
  });

  if (!localizedManifest) {
    return defaultFilename;
  }

  return "manifest_" + localizedManifest.lang + ".webmanifest";
};

exports.default = _default;