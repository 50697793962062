"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NoExampleValueAndExternalValue = void 0;

var NoExampleValueAndExternalValue = function NoExampleValueAndExternalValue() {
  return {
    Example: function Example(example, _ref) {
      var report = _ref.report,
          location = _ref.location;

      if (example.value && example.externalValue) {
        report({
          message: 'Example object can have either `value` or `externalValue` fields.',
          location: location.child(['value']).key()
        });
      }
    }
  };
};

exports.NoExampleValueAndExternalValue = NoExampleValueAndExternalValue;