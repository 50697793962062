"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var items_1 = require("./items");

var def = {
  keyword: "prefixItems",
  type: "array",
  schemaType: ["array"],
  before: "uniqueItems",
  code: function code(cxt) {
    return items_1.validateTuple(cxt, "items");
  }
};
exports.default = def;