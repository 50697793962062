"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ParameterDescription = void 0;

var ParameterDescription = function ParameterDescription() {
  return {
    Parameter: function Parameter(parameter, _ref) {
      var report = _ref.report,
          location = _ref.location;

      if (parameter.description === undefined) {
        report({
          message: 'Parameter object description must be present.',
          location: {
            reportOnKey: true
          }
        });
      } else if (!parameter.description) {
        report({
          message: 'Parameter object description must be non-empty string.',
          location: location.child(['description'])
        });
      }
    }
  };
};

exports.ParameterDescription = ParameterDescription;