"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperationParametersUnique = void 0;

var OperationParametersUnique = function OperationParametersUnique() {
  var seenPathParams;
  var seenOperationParams;
  return {
    PathItem: {
      enter: function enter() {
        seenPathParams = new Set();
      },
      Parameter: function Parameter(parameter, _ref) {
        var report = _ref.report,
            key = _ref.key,
            parentLocations = _ref.parentLocations;
        var paramId = "".concat(parameter.in, "___").concat(parameter.name);

        if (seenPathParams.has(paramId)) {
          report({
            message: "Paths must have unique `name` + `in` parameters.\nRepeats of `in:".concat(parameter.in, "` + `name:").concat(parameter.name, "`."),
            location: parentLocations.PathItem.child(['parameters', key])
          });
        }

        seenPathParams.add("".concat(parameter.in, "___").concat(parameter.name));
      },
      Operation: {
        enter: function enter() {
          seenOperationParams = new Set();
        },
        Parameter: function Parameter(parameter, _ref2) {
          var report = _ref2.report,
              key = _ref2.key,
              parentLocations = _ref2.parentLocations;
          var paramId = "".concat(parameter.in, "___").concat(parameter.name);

          if (seenOperationParams.has(paramId)) {
            report({
              message: "Operations must have unique `name` + `in` parameters. Repeats of `in:".concat(parameter.in, "` + `name:").concat(parameter.name, "`."),
              location: parentLocations.Operation.child(['parameters', key])
            });
          }

          seenOperationParams.add(paramId);
        }
      }
    }
  };
};

exports.OperationParametersUnique = OperationParametersUnique;