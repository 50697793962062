"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var util_1 = require("../../compile/util");

var def = {
  keyword: ["then", "else"],
  schemaType: ["object", "boolean"],
  code: function code(_ref) {
    var keyword = _ref.keyword,
        parentSchema = _ref.parentSchema,
        it = _ref.it;
    if (parentSchema.if === undefined) util_1.checkStrictMode(it, "\"".concat(keyword, "\" without \"if\" is ignored"));
  }
};
exports.default = def;