"use strict";

function __export(m) {
  for (var p in m) {
    if (!exports.hasOwnProperty(p)) exports[p] = m[p];
  }
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

var loader_1 = require("./loader");

exports.load = loader_1.load;
exports.loadAll = loader_1.loadAll;
exports.safeLoad = loader_1.safeLoad;
exports.safeLoadAll = loader_1.safeLoadAll;

var dumper_1 = require("./dumper");

exports.dump = dumper_1.dump;
exports.safeDump = dumper_1.safeDump;
exports.YAMLException = require("./exception");

__export(require("./yamlAST"));

function deprecated(name) {
  return function () {
    throw new Error('Function ' + name + ' is deprecated and cannot be used.');
  };
}

__export(require("./scalarInference"));