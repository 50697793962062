import { traverse } from '../traverse';
export function sampleArray(schema) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var spec = arguments.length > 2 ? arguments[2] : undefined;
  var context = arguments.length > 3 ? arguments[3] : undefined;
  var depth = context && context.depth || 1;
  var arrayLength = Math.min(schema.maxItems != undefined ? schema.maxItems : Infinity, schema.minItems || 1); // for the sake of simplicity, we're treating `contains` in a similar way to `items`

  var items = schema.items || schema.contains;

  if (Array.isArray(items)) {
    arrayLength = Math.max(arrayLength, items.length);
  }

  var itemSchemaGetter = function itemSchemaGetter(itemNumber) {
    if (Array.isArray(schema.items)) {
      return items[itemNumber] || {};
    }

    return items || {};
  };

  var res = [];
  if (!items) return res;

  for (var i = 0; i < arrayLength; i++) {
    var itemSchema = itemSchemaGetter(i);

    var _traverse = traverse(itemSchema, options, spec, {
      depth: depth + 1
    }),
        sample = _traverse.value;

    res.push(sample);
  }

  return res;
}