"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PathDeclarationMustExist = void 0;

var PathDeclarationMustExist = function PathDeclarationMustExist() {
  return {
    PathItem: function PathItem(_path, _ref) {
      var report = _ref.report,
          key = _ref.key;

      if (key.indexOf('{}') !== -1) {
        report({
          message: 'Path parameter declarations must be non-empty. `{}` is invalid.',
          location: {
            reportOnKey: true
          }
        });
      }
    }
  };
};

exports.PathDeclarationMustExist = PathDeclarationMustExist;