"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var limitNumber_1 = require("./limitNumber");

var multipleOf_1 = require("./multipleOf");

var limitLength_1 = require("./limitLength");

var pattern_1 = require("./pattern");

var limitProperties_1 = require("./limitProperties");

var required_1 = require("./required");

var limitItems_1 = require("./limitItems");

var uniqueItems_1 = require("./uniqueItems");

var const_1 = require("./const");

var enum_1 = require("./enum");

var validation = [// number
limitNumber_1.default, multipleOf_1.default, // string
limitLength_1.default, pattern_1.default, // object
limitProperties_1.default, required_1.default, // array
limitItems_1.default, uniqueItems_1.default, // any
{
  keyword: "type",
  schemaType: ["string", "array"]
}, {
  keyword: "nullable",
  schemaType: "boolean"
}, const_1.default, enum_1.default];
exports.default = validation;